import * as Sentry from "@sentry/vue";
import { defineNuxtPlugin, useRuntimeConfig, useRouter } from "#imports";
import pkg from "~~/package.json";
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const { vueApp } = nuxtApp;
  const release = `ticketme-partner-client@${pkg.version}`;
  const environment = config?.public?.enviroment ?? "development";
  const router = useRouter();
  if (!config?.public?.sentry?.dsn) {
    console.warn("Sentry DSN not set, skipping Sentry initialization");
    return;
  }
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config?.public?.sentry?.dsn ?? "",
    release,
    environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          window.location.host,
          `${window.location.protocol}//api.${window.location.host}`,
          `${window.location.protocol}//next-api.${window.location.host}`,
          "https://api.ticketme.biz",
          "https://next-api.ticketme.biz"
        ],
        networkRequestHeaders: [""],
        networkResponseHeaders: [""]
      })
    ],
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    sampleRate: 1,
    tracesSampleRate: 1,
    autoSessionTracking: true,
    attachStacktrace: true,
    beforeSend(event, hint) {
      if (event.exception) {
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        );
      }
      return event;
    }
  });
  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2e3,
      hooks: ["activate", "mount", "update"]
    })
  );
  Sentry.attachErrorHandler(vueApp, {
    logErrors: true,
    attachProps: true,
    trackComponents: true,
    timeout: 2e3,
    hooks: ["activate", "mount", "update"]
  });
  nuxtApp.hooks.hook("app:error", (error) => {
    Sentry?.captureException ? Sentry.captureException(error) : console.log(Sentry);
  });
  nuxtApp.hooks.hook("vue:error", (error) => {
    Sentry?.captureException ? Sentry.captureException(error) : console.log(Sentry);
  });
  return {
    provide: {
      sentry: Sentry,
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  };
});
